<template>
	<div>
		<Nav />
	  <div class="dashboard">
      <router-view :key="$route.params.id" />
	  </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'dashboard',
  components: {
  	Nav,
    Footer
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile', 'products']),
  },
  // async mounted () {
  //   if (!this.business && this.business == null) {
  //     this.$store.dispatch("getBusiness");
  //   }
  // },
}
</script>